











































import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";
import {searchTaxonomy} from "@/utils/searching";
import {vxm} from "@/store";
import {TaxonomyBrief} from "@/service";

@Component
export default class SpeciesChooser extends Vue {

  private get selected(): number[] {
    return vxm.portraitsModule.currentPortrait?.presences.map(s => s.taxonomy) || [];
  }

  private set selected(v: number[]) {
    const before = vxm.portraitsModule.currentPortrait?.presences.map(s => s.taxonomy) || [];

    console.log("TaxonomyPortrait.selected changed", before, v)

    if (v.length > before.length) {
      const taxonomyId = v.filter(t => !before.includes(t))[0] || 0;
      if (taxonomyId > 0) {
        this.$emit('on-add', taxonomyId);
      }
    }

    // this.$emit('on-selected', before, v)
  }

  @Prop({default: [], required: true})
  readonly allSpecies!: TaxonomyBrief[];

  @Prop({default: '向下图添加出现的鸟种，使用拼音可以快速搜索'})
  readonly label!: string;

  @Prop({default: true})
  readonly multiple!: boolean;

  @Prop({default: false})
  readonly returnObject !: boolean;

  private filterIt(item: any, queryText: string, itemText: string): boolean {
    return searchTaxonomy(item, queryText, itemText);
  }

  private get urlParamSpecies(): string {
    return this.$route.params.species || "";
  }

  private chipColor(species: number): string {
    return this.$route.name === 'TaxonomyPresence' && species.toString() == this.urlParamSpecies
      ? "orange" : 'grey';
  }

  private closeSpecies(item: TaxonomyBrief) {
    console.log("closeSpecies", item)
    this.$emit("on-delete", item);
  }

  private onOuterClick() {
    this.$emit("on-outer-click");
  }

  private changeSpeciesUrl(species: string) {
    if (this.$route.name != 'TaxonomyPresence' || species != this.urlParamSpecies) {
      this.$router.replace({
        name: "TaxonomyPresence",
        params: {
          list: this.$route.params.list,
          node: this.$route.params.node,
          media: this.$route.params.media,
          species: species,
        }
      });
    }
  }

  private backToMarkingUrl() {
    if (this.$route.name != 'TaxonomyPortrait') {
      this.$router.replace({
        name: "TaxonomyPortrait",
        params: {
          list: this.$route.params.list,
          node: this.$route.params.node,
          media: this.$route.params.media,
        }
      });
    }
  }

  @Watch('selected_')
  private onSelectionChanged(val: number[], oldVal: number[]) {
    console.log(`selected`)

    this.$emit("update:selected", val);

    if (val.length < oldVal.length) {
      this.backToMarkingUrl();
    }
    // else {
    //   const added = val.filter((v) => !oldVal.includes(v));
    //
    //   if (added.length > 0) {
    //     this.changeSpeciesUrl(added[0].toString());
    //   }
    // }

  }

}
