





























import Vue from "vue";
import {Component} from "vue-property-decorator";
import SpeciesChooser from "@/components/SpeciesChooser.vue";
import {Configuration, DefaultApi, TaxonomyBrief, TaxonomyMedia} from "@/service";
import {vxm} from "@/store";


@Component({
  components: {
    SpeciesChooser,
  }
})
export default class CreatePortraitPage extends Vue {

  private images: TaxonomyMedia[] = [];

  private imageBase = vxm.main.mediaUrlBase;

  private imageUrl(md5: string):string {
    return `${vxm.main.mediaUrlBase}/${md5}`;
  }

  private service = new DefaultApi(new Configuration({
    basePath: process.env.VUE_APP_SERVICE_BASE_URL,
  }));

  private get allSpecies(): TaxonomyBrief[] {
    return vxm.taxonomyStore.allSpecies;
  }

  private onSelected(species: TaxonomyBrief) {
    let self: this;
    self = this;

    this.service.listImages({
      listId: +this.$route.params.list, nodeId: species.node
    }).then(function (images) {
      self.images.splice(0);
      self.images.push(...images);
    }, function (error) {
      vxm.main.onError(`listPortraits API: ${error}`)
    })
  }
}
